html {
  font-size: 14px;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  background-color: var(--color-background);
  color: var(--text-color);
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /*colores DS*/
  --color-primary: #0994ff;
  --color-primary-claro: #c2daff;
  --color-primary-hover: #037ded;
  --color-success: #1ab759;
  --color-success-claro: #edfcf3;
  --color-success-hover: #018938;
  --color-error: #d81d1d;
  --color-error-claro: #fdeded;
  --color-error-hover: #c60404;
  --color-warning: #f2a500;
  --color-warning-claro: #fff2d6;
  --color-background: #f5f5f8;
  --color-blanco: #ffffff;
  --color-B1: #444444;
  --color-B2: #555555;
  --color-B3: #777777;
  --color-B4: #999999;
  --color-B5: #dddddd;
  --color-B6: #F9FAFB;
  --color-naranjo-primary: #ff6b00;
  --color-naranjo-claro: #fff3eb;
  --color-layout-tabla: rgba(246, 247, 250, 0.5);
  --color-fondo-modal: rgba(246, 247, 250, 0.1);
  --color-active: #e6f2fd;
  --color-zebra: #fafbfc;
  --color-tag-success-background: #DCFCE7;
  --color-tag-success-text: #15803D;
  --color-tag-warning-background: #FEF9C3;
  --color-tag-warning-text: #A16207;
  --border-color: #E2E8F0;
  --card-bg: #F8FAFC;
  --text-dark: #334155;

}
