// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$newpagescm-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($newpagescm-theme);
}

@import "primeicons/primeicons.css";

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($newpagescm-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($newpagescm-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

label{
  letter-spacing:0.4px;
}

h3{
  letter-spacing:0.4px;
}

p{
  margin: 0px;
  letter-spacing:0.4px;
}

body{
  background-color: var(--color-background);
}

th{
  font-size: 14px;
  font-weight: 700;
}

td{
  font-size: 14px;
  font-weight: 400;
}

:root {
  /*colores DS*/
  --color-primary: #0994FF;
  --color-primary-claro: #C2DAFF;
  --color-primary-hover: #037DED;
  --color-sucess: #1AB759;  
  --color-sucess-claro: #EDFCF3;  
  --color-sucess-hover: #018938;
  --color-error: #D81D1D;   
  --color-error-claro: #FDEDED;    
  --color-error-hover: #C60404; 
  --color-warning: #F2A500;
  --color-warning-claro: #FFF2D6;
  --color-background: #F5F5F8;
  --color-blanco: #FFFFFF;  
  --color-B1: #444444;
  --color-B2: #555555;
  --color-B3: #777777;
  --color-B4: #999999;
  --color-B5: #DDDDDD;
  --color-naranjo-primary: #FF6B00;
  --color-naranjo-claro: #FFF3EB;
  --color-layout-tabla:rgba(246, 247, 250, 0.5);
  --color-fondo-modal:rgba(246, 247, 250, 0.1);
  --color-active: #E6F2FD;
  --color-zebra: #FAFBFC;
}
